<template>
  <div class="custom-height-100p products">
    <div class="title">
      所选套餐
    </div>
    <div class="package-box">
      <img
        v-if="data.package_image_url"
        class="package-img"
        alt="img"
        :src="data.package_image_url"
      >
      <img
        v-else
        class="package-img"
        alt="img"
        src="/temp/no_picture.png"
      >
      <div class="package-info">
        <div class="package-name">{{ data.package_name }}</div>
        <div class="package-fee">￥ {{ data.package_fee | formatCurrency }}</div>
      </div>
    </div>

    <div v-for="order in data.orders" :key="order.order_no" class="order-box">
      <div class="title">
        <div class="left">
          {{ order.order_no }}
        </div>
        <div>
          {{ order.pay_way }}
        </div>
      </div>
      <div v-for="product in order.other_list" :key="product.id">
        <div class="product-box">
          <div class="product-content">
            <div class="product">
              <img
                v-if="product.goods_pic"
                class="product-img"
                alt="img"
                :src="product.goods_pic"
              >
              <img
                v-else
                class="product-img"
                alt="img"
                src="/temp/no_picture.png"
              >
              <div class="product-info">
                <div>{{ product.goods_name }}</div>
                <div>{{ product.goods_price_display }}</div>
                <div>
                  <span class="spec-info">
                    {{ product.goods_spec_name }}
                  </span>
                </div>
              </div>
            </div>
            <div class="give">
              <div><div v-if="product.give" class="give-btn">惠民赠送</div></div>
              <div class="count">数量：{{ product.goods_quantity }}</div>
            </div>
          </div>
          <div v-if="product.more_info && product.more_info.length > 0">
            <a-divider />
            <div class="more-info">
              <div>挽联信息：</div>
              <div @click="showElegiacCoupletModal(product.more_info)">点击查看</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <elegiac-couplet
      v-if="isShowElegiacCoupletModal"
      :visible.sync="isShowElegiacCoupletModal"
      :data="elegiacCouplets"
    />
  </div>
</template>

<script>
import { findMobileServiceOrderOtherItems } from '@/api/service_order'
export default {
  name: 'AlreadySub',
  components: {
    ElegiacCouplet: () => import('@/views/products/mobile/ElegiacCouplet')
  },
  data() {
    return {
      data: {},
      isShowElegiacCoupletModal: false,
      elegiacCouplets: []
    }
  },
  computed: {
    serviceOrderId() {
      return parseInt(this.$route.query.service_order_id)
    },

    hallId() {
      return parseInt(this.$route.query.hall_id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findMobileServiceOrderOtherItems(this.serviceOrderId).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    },

    showElegiacCoupletModal(elegiacCouplets) {
      this.isShowElegiacCoupletModal = true
      this.elegiacCouplets = elegiacCouplets
    }
  }
}
</script>
<style lang="less" scoped>
.products {
  overflow: auto;
  font-size: 20px;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  background-color: #5b7b64;

  .left {
    font-weight: bold;
  }
}

.package-box {
  display: flex;
  margin: 20px 0;
  padding: 40px;
  background-color: #2a4c39;

  .package-img {
    width: 150px;
    height: 150px;
  }

  .package-info {
    margin-left: 20px;

    .package-fee {
      margin-top: 20px;
    }
  }
}

.order-box {
  margin-bottom: 20px;

  .product-box {
    margin: 20px 0;
    padding: 40px;
    background-color: #2a4c39;

    .product-content {
      display: flex;
      justify-content: space-between;
    }

    .more-info {
      display: flex;
      justify-content: space-between;
    }

    .product {
      display: flex;
    }

    .give {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .give-btn {
        padding: 5px 10px;
        border-radius: 35px;
        background-color: #a88644;
      }

      .count {
        text-align: right;
      }
    }
  }

  .product-img {
    width: 150px;
    height: 150px;
  }

  .product-info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin-left: 20px;
    font-size: 20px;

    .spec-info {
      padding: 5px 10px;
      color: #355b40;
      border-radius: 35px;
      background-color: #ebf2f0;
    }
  }
}

</style>

